import React from "react"
import Nav from "./Nav"
import "./legacy.css"
import "./main.css"

const Layout = ({ children }) => {
  return (
    <div id="container" className="site">
      <Nav />
      <main id="main_content">{children}</main>
    </div>
  )
}

export default Layout
