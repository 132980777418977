import React from "react"
import { Link } from "gatsby"
import Logo from "../images/logo.jpg"

const Nav = () => {
  return (
    <div id="left_sidebar">
      <ul id="main_list">
        <li className="cs_logo">
          <Link to="/">
            <img
              src={Logo}
              alt="Zoe Costa Rica logo"
              title="Market Street Fellowship Home Page"
              width="130"
              height="92"
              border="0"
              className="logo"
            />
          </Link>
        </li>
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <a href="https://hender.blog">
            <b>La Senda Antigua</b>
          </a>
        </li>
        <li>
          <a href="https://bibliotecadelosamigos.org">
            <b>Biblioteca de los Amigos</b>
          </a>
        </li>
        <li>
          <Link to="/publicaciones">Publicaci&oacute;nes</Link>
        </li>
        <li>
          <Link to="/">Mensajes</Link>
          <ul>
            <li>
              <Link to="/2023mensajes" style={{ fontWeight: `bold` }}>
                2023 Mensajes
              </Link>
            </li>
            <li>
              <Link to="/2019mensajes">2019 Mensajes</Link>
            </li>
            <li>
              <Link to="/2018mensajes">2018 Mensajes</Link>
            </li>
            <li>
              <Link to="/2017mensajes">2017 Mensajes</Link>
            </li>
            <li>
              <Link to="/2015mensajes">2015 Mensajes</Link>
            </li>
            <li>
              <Link to="/2013mensajes">2013 Mensajes</Link>
            </li>
            <li>
              <Link to="/2012mensajes">2012 Mensajes</Link>
            </li>
            <li>
              <Link to="/2011mensajes">2011 Mensajes</Link>
            </li>
            <li>
              <Link to="/2010mensajes">2010 Mensajes</Link>
            </li>
            <li>
              <Link to="/2009mensajes">2009 Mensajes</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/">Estudios bíblicos</Link>
        </li>
        <ul>
          <li>
            <Link to="/genesis">G&eacute;nesis</Link>
          </li>
          <li>
            <Link to="/exodo">&Eacute;xodo</Link>
          </li>
          <li>
            <Link to="/levitico">Levítico</Link>
          </li>
          <li>
            <Link to="/numeros">Números</Link>
          </li>
          <li>
            <Link to="/efesios">Efesios</Link>
          </li>
        </ul>
        <li>
          <a href="https://www.marketstreetfellowship.com">
            Resources in English
          </a>
        </li>
        <li>
          <a href="https://hender.blog/contacto">Contacto</a>
          <form
            id="search"
            name="frmSearch"
            method="get"
            action="https://www.google.com/search"
          >
            <label htmlFor="q">buscar en el sitio:</label>
            <input type="hidden" name="sitesearch" value="zoecostarica.com" />
            <input
              className="searchbox"
              name="q"
              type="text"
              id="txtSearch"
              size="15"
            />
            <input name="go" type="submit" value="go" size="8" />
          </form>
        </li>
      </ul>
    </div>
  )
}

export default Nav
